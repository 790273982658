import React from 'react';
import styles from './list.module.scss';

export function ListItem(props) {
  return <li className={styles.listItem}>{props.children}</li>;
}

export function List(props) {
  return <ul className={styles.list}>{props.children}</ul>;
}
