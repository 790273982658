import React, { useState } from 'react';
import { useSprings, animated as a } from 'react-spring';
import { Figure } from './figure';
import styles from './slideshow.module.scss';

/**
 * @return {null}
 */
export function Slideshow(props) {
  const { slides } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const springs = useSprings(
    slides.length,
    slides.map((_, index) => ({
      transform: `translateX(${index === activeIndex ? 0 : index < activeIndex ? -100 : 100}%)`,
    }))
  );

  if (!slides) return null;

  return (
    <div className={styles.root}>
      <ul className={styles.slider}>
        {slides.map((slide, i) => {
          return (
            <a.li style={springs[i]} key={slide._key} className={styles.slide}>
              {slide.asset && <Figure style={{}} asset={slide.asset} alt={slide.alt} caption={slide.caption} />}
            </a.li>
          );
        })}
      </ul>
      <div className={styles.actions}>
        <button onClick={() => setActiveIndex(activeIndex - 1)} disabled={activeIndex === 0} />
        <button onClick={() => setActiveIndex(activeIndex + 1)} disabled={activeIndex === slides.length - 1} />
      </div>
    </div>
  );
}
