import React from 'react';
import ReactPlayer from 'react-player';
import styles from './player.module.scss';

export function Player({ url }) {
  return (
    <div className={styles.playerWrapper}>
      <ReactPlayer
        url={url}
        className={styles.player}
        light={true}
        width="100%"
        height="100%"
        playing={true}
        controls={true}
        config={{
          youtube: {
            embedOptions: {
              host: 'https://www.youtube-nocookie.com',
            },
          },
        }}
        playIcon={<div className={styles.playIcon} />}
      />
    </div>
  );
}
